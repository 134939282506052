import React from "react";
import { getSystemValues, updateSystemValues } from "../utils/api";
import { Button, Input, Modal, Space, Table, message, Typography } from "antd";
import Head from "../components/common/Head";

const ServiceTax = () => {
  const [open, setOpen] = React.useState(false);

  const getAllSystemValues = async () => {
    setLoading(true);
    const response = await getSystemValues();
    if (response[0]) {
      setData(response[1]);
    }
    setLoading(false);
  };

  const changeSystemValues = async () => {
    setLoading(true);
    setOpen(true);
    const response = await updateSystemValues(data);
    if (response[0]) {
      messageApi.success("Successfully Updated Values");
      getAllSystemValues();
    }
    setOpen(false);
    setLoading(false);
  };

  const [data, setData] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  React.useEffect(() => {
    getAllSystemValues();
  }, []);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {contextHolder}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {" "}
        <Head title="Service Tax" />
        <Table
          loading={loading}
          dataSource={[data]}
          columns={[
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle" direction="vertical">
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setOpen(true);
                      setData(record);
                    }}
                  >
                    Edit Values
                  </Button>
                </Space>
              ),
            },
            {
              title: "(0-10k)",
              dataIndex: "serviceTax1",
              key: "serviceTax1",
            },
            {
              title: "(10k-20k)",
              dataIndex: "serviceTax2",
              key: "serviceTax2",
            },
            {
              title: "(20k-30k)",
              dataIndex: "serviceTax3",
              key: "serviceTax3",
            },
            {
              title: "(30k-40k)",
              dataIndex: "serviceTax4",
              key: "serviceTax4",
            },
            {
              title: "(40k-50k)",
              dataIndex: "serviceTax5",
              key: "serviceTax5",
            },
            {
              title: "(50k-60k)",
              dataIndex: "serviceTax6",
              key: "serviceTax6",
            },
            {
              title: "(60k-70k)",
              dataIndex: "serviceTax7",
              key: "serviceTax7",
            },
            {
              title: "(70k-80k)",
              dataIndex: "serviceTax8",
              key: "serviceTax8",
            },
            {
              title: "(80k-90k)",
              dataIndex: "serviceTax9",
              key: "serviceTax9",
            },
            {
              title: "(90k-1L)",
              dataIndex: "serviceTax10",
              key: "serviceTax10",
            },
            {
              title: "(1L-5L)",
              dataIndex: "serviceTax11",
              key: "serviceTax11",
            },
          ]}
        />
        <Modal
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          confirmLoading={loading}
          onOk={changeSystemValues}
          title={"Update"}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Typography style={{ marginRight: 16 }}>0-10k</Typography>
            <Input
              placeholder="0-10k"
              onChange={(e) =>
                setData((prev) => ({ ...prev, serviceTax1: e.target.value }))
              }
              value={data?.serviceTax1}
            />
            <Typography style={{ marginRight: 16 }}>10k-20k</Typography>
            <Input
              placeholder="10k-20k"
              onChange={(e) =>
                setData((prev) => ({ ...prev, serviceTax2: e.target.value }))
              }
              value={data?.serviceTax2}
            />
            <Typography style={{ marginRight: 16 }}>20k-30k</Typography>
            <Input
              placeholder="20k-30k"
              onChange={(e) =>
                setData((prev) => ({ ...prev, serviceTax3: e.target.value }))
              }
              value={data?.serviceTax3}
            />
            <Typography style={{ marginRight: 16 }}>30k-40k</Typography>
            <Input
              placeholder="30k-40k"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax4: e.target.value,
                }))
              }
              value={data?.serviceTax4}
            />
            <Typography style={{ marginRight: 16 }}>40k-50k</Typography>
            <Input
              placeholder="40k-50k"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax5: e.target.value,
                }))
              }
              value={data?.serviceTax5}
            />
            <Typography style={{ marginRight: 16 }}>50k-60k</Typography>
            <Input
              placeholder="50k-60k"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax6: e.target.value,
                }))
              }
              value={data?.serviceTax6}
            />
            <Typography style={{ marginRight: 16 }}>60k-70k</Typography>
            <Input
              placeholder="60k-70k"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax7: e.target.value,
                }))
              }
              value={data?.serviceTax7}
            />
            <Typography style={{ marginRight: 16 }}>70k-80k</Typography>
            <Input
              placeholder="70k-80k"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax8: e.target.value,
                }))
              }
              value={data?.serviceTax8}
            />
            <Typography style={{ marginRight: 16 }}>80k-90k</Typography>
            <Input
              placeholder="80k-90k"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax9: e.target.value,
                }))
              }
              value={data?.serviceTax9}
            />
            <Typography style={{ marginRight: 16 }}>90k-1L</Typography>
            <Input
              placeholder="90k-1L"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax10: e.target.value,
                }))
              }
              value={data?.serviceTax10}
            />
            <Typography style={{ marginRight: 16 }}>1L-5L</Typography>
            <Input
              placeholder="1L-5L"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  serviceTax11: e.target.value,
                }))
              }
              value={data?.serviceTax11}
            />
          </Space>
        </Modal>
      </Space>
    </Space>
  );
};

export default ServiceTax;
