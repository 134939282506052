import { Image, Table } from "antd";
import React, { useState } from "react";
import Head from "../components/common/Head";
import { getAllUsers } from "../utils/api";
import useValidate from "../hooks/useValidate";
import useSearch from "../components/common/useSearch";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const search = useSearch();
  const getAllUserData = async () => {
    setLoading(true);
    const [success, response] = await getAllUsers();
    if (success) setUsers(response);
    setLoading(false);
  };

  useValidate();
  React.useEffect(() => {
    getAllUserData();
  }, []);
  return (
    <>
      <Head title={"Users"} />
      <Table
        rowKey={(record) => record._id}
        loading={loading}
        scroll={{ x: 1000 }}
        dataSource={users}
        columns={[
          {
            title: "Image",
            dataIndex: "photo",
            key: "photo",
            render: (text) => <Image src={text} width={50} height={50} />,
          },
          {
            title: "Name",
            dataIndex: "firstName",
            key: "name",
            render: (text, record) => (
              <div>
                {text} {record.lastName}
              </div>
            ),
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ...search("phoneNumber"),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ...search("email"),
          },
          {
            title: "DOB",
            dataIndex: "dateOfBirth",
            key: "dob",
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
          },
          {
            title: "Tenure",
            dataIndex: "tenure",
            key: "tenure",
          },
          {
            title: "Education",
            dataIndex: "education",
            key: "education",
          },
          {
            title: "Employee Type",
            dataIndex: "employeeType",
            key: "employeeType",
          },
          {
            title: "Annual Income",
            dataIndex: "annualIncome",
            key: "annualIncome",
          },
          {
            title: "Monthly Income",
            dataIndex: "monthlyIncome",
            key: "monthlyIncome",
          },
          {
            title: "Gender",
            dataIndex: "gender",
            key: "gender",
          },
          {
            title: "Pan Card",
            dataIndex: "pancardNumber",
            key: "pancardNumber",
          },
          {
            title: "Account Number",
            dataIndex: "accountNumber",
            key: "accountNumber",
          },
          {
            title: "Bank Name",
            dataIndex: "bankName",
            key: "bankName",
          },
          {
            title: "IFSC",
            dataIndex: "ifsc",
            key: "ifsc",
          },
          {
            title: "Pincode",
            dataIndex: "pincode",
            key: "pincode",
          },
          {
            title: "Creation Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => new Date(text).toLocaleDateString(),
          },
        ]}
      />
    </>
  );
};

export default Users;
