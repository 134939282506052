import React from "react";
import { getSystemValues, updateSystemValues } from "../utils/api";
import { Button, Modal, Space, Table, message } from "antd";
import Head from "../components/common/Head";
import TextArea from "antd/es/input/TextArea";

const PrivacyPolicy = () => {
  const [open, setOpen] = React.useState(false);

  const getAllSystemValues = async () => {
    setLoading(true);
    const response = await getSystemValues();
    if (response[0]) {
      setData(response[1]);
    }
    setLoading(false);
  };

  const changeSystemValues = async () => {
    setLoading(true);
    setOpen(true);
    const response = await updateSystemValues(data);
    if (response[0]) {
      messageApi.success("Successfully Updated Values");
      getAllSystemValues();
    }
    setOpen(false);
    setLoading(false);
  };

  const [data, setData] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  React.useEffect(() => {
    getAllSystemValues();
  }, []);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {contextHolder}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Head title="Privacy Policy" />
        <Table
          loading={loading}
          dataSource={[data]}
          columns={[
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle" direction="vertical">
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setOpen(true);
                      setData(record);
                    }}
                  >
                    Edit
                  </Button>
                </Space>
              ),
            },
            {
              title: "Privacy Policy",
              dataIndex: "privacyPolicy",
              key: "privacyPolicy",
            },
          ]}
        />
        <Modal
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          confirmLoading={loading}
          onOk={changeSystemValues}
          title={"Update"}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <TextArea
              placeholder="Privacy Policy"
              onChange={(e) =>
                setData((prev) => ({ ...prev, privacyPolicy: e.target.value }))
              }
              value={data?.privacyPolicy}
            />
          </Space>
        </Modal>
      </Space>
    </Space>
  );
};

export default PrivacyPolicy;
