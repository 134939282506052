import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layouts from "./components/common/Layout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Users from "./pages/Users";
import AdminValues from "./pages/AdminValues";
import AboutUs from "./pages/AboutUs";
import TermsAndConditions from "./pages/TermsConditons";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import GST from "./pages/GST";
import ServiceTax from "./pages/ServiceTax";

function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Layouts>
              <Dashboard />
            </Layouts>
          }
        />
        <Route
          path="/admin-values"
          element={
            <Layouts>
              <AdminValues />
            </Layouts>
          }
        />
        <Route
          path="/users"
          element={
            <Layouts>
              <Users />
            </Layouts>
          }
        />
        <Route
          path="/terms-amnd-condition"
          element={
            <Layouts>
              <TermsAndConditions />
            </Layouts>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layouts>
              <AboutUs />
            </Layouts>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layouts>
              <PrivacyPolicy />
            </Layouts>
          }
        />
        <Route
          path="/refund-policy"
          element={
            <Layouts>
              <RefundPolicy />
            </Layouts>
          }
        />
        <Route
          path="/gst"
          element={
            <Layouts>
              <GST />
            </Layouts>
          }
        />
        <Route
          path="/service-tax"
          element={
            <Layouts>
              <ServiceTax />
            </Layouts>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
