import React from "react";
import { getSystemValues, updateSystemValues } from "../utils/api";
import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  message,
  Typography,
  Switch,
} from "antd";
import Head from "../components/common/Head";

const AdminValues = () => {
  const [open, setOpen] = React.useState(false);

  const getAllSystemValues = async () => {
    setLoading(true);
    const response = await getSystemValues();
    if (response[0]) {
      console.log(response[1]);
      setData(response[1]);
    }
    setLoading(false);
  };

  const changeSystemValues = async () => {
    setLoading(true);
    setOpen(true);
    const response = await updateSystemValues(data);
    if (response[0]) {
      messageApi.success("Successfully Updated Values");
      getAllSystemValues();
    }
    setOpen(false);
    setLoading(false);
  };

  const [data, setData] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  React.useEffect(() => {
    getAllSystemValues();
  }, []);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {contextHolder}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {" "}
        <Head title="Admin Values" />
        <Table
          loading={loading}
          dataSource={[data]}
          columns={[
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle" direction="vertical">
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setOpen(true);
                      setData(record);
                    }}
                  >
                    Edit Values
                  </Button>
                </Space>
              ),
            },
            {
              title: "Duration For Account Statement",
              dataIndex: "durationForAccountStatement",
              key: "durationForAccountStatement",
            },
            {
              title: "Duration For Loan",
              dataIndex: "durationForLoan",
              key: "durationForLoan",
            },
            {
              title: "Duration For Update Information",
              dataIndex: "durationForUpdateInformaion",
              key: "durationForUpdateInformaion",
            },
            {
              title: "Interest",
              dataIndex: "interest",
              key: "interest",
            },
            {
              title: "UPI",
              dataIndex: "upi",
              key: "upi",
            },
            {
              title: "Merchant Code",
              dataIndex: "merchantCode",
              key: "merchantCode",
            },
            {
              title: "New Screen",
              dataIndex: "isEnable",
              key: "isEnable",
              render: (text, record) => <div>{text ? "Yes" : "No"}</div>,
            },
          ]}
        />
        <Modal
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          confirmLoading={loading}
          onOk={changeSystemValues}
          title={"Update"}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Typography style={{ marginRight: 16 }}>Interest(%)</Typography>
            <Input
              placeholder="Interest"
              onChange={(e) =>
                setData((prev) => ({ ...prev, interest: e.target.value }))
              }
              value={data?.interest}
            />
            <Typography style={{ marginRight: 16 }}>
              Duration For Loan
            </Typography>
            <Input
              placeholder="Duration For Loan"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  durationForLoan: e.target.value,
                }))
              }
              value={data?.durationForLoan}
            />
            <Typography style={{ marginRight: 16 }}>
              Duration For Update Informaion
            </Typography>
            <Input
              placeholder="Duration For Update Informaion"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  durationForUpdateInformaion: e.target.value,
                }))
              }
              value={data?.durationForUpdateInformaion}
            />
            <Typography style={{ marginRight: 16 }}>
              Duration For Account Statement
            </Typography>
            <Input
              placeholder="Duration For Account Statement"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  durationForAccountStatement: e.target.value,
                }))
              }
              value={data?.durationForAccountStatement}
            />
            <Typography style={{ marginRight: 16 }}>UPI</Typography>
            <Input
              placeholder="UPI"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  upi: e.target.value,
                }))
              }
              value={data?.upi}
            />
            <Typography style={{ marginRight: 16 }}>Merchant Code</Typography>
            <Input
              placeholder="Merchant Code"
              onChange={(e) =>
                setData((prev) => ({ ...prev, merchantCode: e.target.value }))
              }
              value={data?.merchantCode}
            />
            <Typography style={{ marginRight: 16 }}>New Screen</Typography>
            <Switch
              checked={data?.isEnable}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  isEnable: e,
                }));
              }}
            />
          </Space>
        </Modal>
      </Space>
    </Space>
  );
};

export default AdminValues;
