import {
  PieChartOutlined,
  UserOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme, Typography } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../utils/store";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}
const items = [
  getItem("Dashboard", "1", <PieChartOutlined />, null, "/"),
  getItem("Users", "2", <UserOutlined />, null, "/users"),
  getItem("Admin Values", "3", <FileDoneOutlined />, null, "/admin-values"),
  getItem(
    "Terms and Conditions",
    "4",
    <FileDoneOutlined />,
    null,
    "/terms-amnd-condition"
  ),
  getItem("About Us", "5", <FileDoneOutlined />, null, "/about-us"),
  getItem("Privacy Policy", "6", <FileDoneOutlined />, null, "/privacy-policy"),
  getItem("Refund Policy", "7", <FileDoneOutlined />, null, "/refund-policy"),
  getItem("GST", "8", <FileDoneOutlined />, null, "/gst"),
  getItem("Service Tax", "9", <FileDoneOutlined />, null, "/service-tax"),
];
const Layouts = ({ children }) => {
  const logout = useStore((state) => state.logout);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div
          style={{
            padding: 16,
          }}
        >
          <Typography.Title level={2} style={{ margin: 0 }} type={"warning"}>
            CREDITBEE
          </Typography.Title>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          selectedKeys={
            items && items.find((item) => item.path === location.pathname)?.key
          }
        >
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "#001529",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: collapsed ? "space-between" : "flex-end",
              alignItems: "center",
              gap: 16,
              padding: collapsed ? "0rem 1rem" : "1rem",
            }}
          >
            {collapsed && (
              <Typography.Title
                level={3}
                style={{ margin: 0 }}
                type={"warning"}
              >
                CREDITBEE
              </Typography.Title>
            )}
            <Button type="primary" onClick={logout}>
              Logout
            </Button>
          </div>
        </Header>
        <Content style={{}}>
          <div
            style={{
              minHeight: "90dvh",
              background: colorBgContainer,
              padding: 24,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          CREDITBEE
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Layouts;
