import create from "zustand";
import { persist, devtools } from "zustand/middleware";

let store = (set) => ({
  isLoggedIn: true,
  login: (user) =>
    set((state) => ({
      isLoggedIn: true,
    })),
  logout: () => set((state) => ({ isLoggedIn: false })),
});

store = persist(store, { name: "creditbee" });
store = devtools(store);

const useStore = create(store);

export default useStore;
