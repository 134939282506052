import { Card, Col, Row, Statistic, Typography } from "antd";
import React from "react";
import { getAllUsers } from "../utils/api";
import { UserOutlined } from "@ant-design/icons";
import useValidate from "../hooks/useValidate";

const Dashboard = () => {
  const [data, setData] = React.useState(0);
  const getAllUserData = async () => {
    const [success, response] = await getAllUsers();
    if (success) setData(response.length);
  };

  useValidate();
  React.useEffect(() => {
    getAllUserData();
  }, []);
  return (
    <>
      <Typography.Title level={1} style={{ margin: 0 }}>
        Dashboard
      </Typography.Title>
      {/* dashboard colorfull card for data  diff bg color  */}
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={8}>
          <Card>
            <Statistic
              title="Total Users"
              value={data}
              //title color

              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
