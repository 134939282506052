import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

const useValidate = () => {
  const navigate = useNavigate();
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }
  }, [isLoggedIn, navigate]);
  return [navigate, isLoggedIn];
};

export default useValidate;
