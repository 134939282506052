import { Col, Row, Typography } from "antd";
import React from "react";

const Head = ({ title }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col className="gutter-row" flex={1}>
        <Typography.Title level={2} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default Head;
