import axios from "axios";

const BASEURL = "https://api.creditbee.cfd";

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${BASEURL}/admin/users`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSystemValues = async () => {
  try {
    const response = await axios.get(`${BASEURL}/system-values`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSystemValues = async (data) => {
  try {
    const response = await axios.put(`${BASEURL}/admin/system-values`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
