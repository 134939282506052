import { Button, Form, Input, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";
import useValidate from "../hooks/useValidate";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const login = useStore((state) => state.login);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const loginUser = async (values) => {
    setLoading(true);
    if (values.email === "admin@gmail.com" && values.password === "admin123") {
      login();
      navigate("/");
    } else {
      messageApi.error("Enter Valid Credentials");
    }
    setLoading(false);
  };

  useValidate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {contextHolder}
      <Form
        className="login-card"
        name="basic"
        labelCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={loginUser}
        autoComplete="off"
      >
        <div className="login-card-header">
          <h1>Admin Login</h1>
        </div>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            placeholder="
          Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            placeholder="
          Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
